export enum PlacementCategory {
	STANDALONE = 'STANDALONE',
	IN_CONTENT = 'IN_CONTENT',
	IN_LIST = 'IN_LIST',
}

export function getPlacementCategory(value: string): PlacementCategory | undefined {
    return Object.values(PlacementCategory).includes(value as PlacementCategory)
        ? (value as PlacementCategory)
        : undefined;
}