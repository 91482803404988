import { useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, NavLink, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { DEFAULT_PAGE_SIZE, queryClient } from "../../api/common";
import adService from "../../api/ad.service";
import Paged from "../../components/pagination/Paged";
import { format } from "date-fns";
import { AdStatus } from "../../models/ui/ad-status";
import { Ad } from "../../models/api/ad.api";

const statusClasses = {
  [AdStatus.ACTIVE]: "bg-green-50 text-green-700ring-green-600/20",
  [AdStatus.SCHEDULED]: "bg-green-50 text-green-700ring-green-600/20",
  [AdStatus.DRAFT]: "bg-gray-50 text-gray-700 ring-gray-600/20",
  [AdStatus.ENDED]: "bg-red-50 text-red-700 ring-red-600/20",
  [AdStatus.UNAPPROVED]: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
  [AdStatus.PENDING]: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
}

function getStatus(ad: Ad) {
  if (!ad.targets || ad.targets.length === 0) {
    return AdStatus.DRAFT;
  }
  if (ad.endDate < new Date()) {
    return AdStatus.ENDED;
  }
  if (ad.targets.filter(t => !t.approved).length > 0) {
    return AdStatus.UNAPPROVED;
  }
  if (ad.targets.filter(t => !t.paid).length > 0) {
    return AdStatus.PENDING;
  }
  if (ad.startDate > new Date()) {
    return AdStatus.SCHEDULED;
  }

  return AdStatus.ACTIVE;
}

const AdsList = () => {
  const params = useParams();
  const pageNumber = params.pageNumber ? Number(params.pageNumber) : 1;
  const navigate = useNavigate();

  const { data, isPending, isLoading, isRefetching } = useQuery({
    queryKey: ["ads", pageNumber - 1, DEFAULT_PAGE_SIZE],
    queryFn: () => adService.list(pageNumber - 1, DEFAULT_PAGE_SIZE),
  });

  const openDetail = (adId: number) => {
    navigate(`/ads/${adId}`);
  };

  const openSetup = () => {
    navigate(`/ads/setup`);
  };

  return (
    <>
      <div className="mt-4 sm:flex sm:w-auto justify-end sm:px-6 md:px-8 lg:px-8">
        <button
          type="button"
          onClick={openSetup}
          className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Create
        </button>
      </div>
      <Paged
        currentPage={pageNumber}
        pagesCount={data?.data.totalPages || 0}
        itemName="ad"
        totalItems={data?.data.totalElements || 0}
        className={`sm:px-6 md:px-8 lg:px-8 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}
      >
        <ul role="list" className={`divide-y divide-gray-100 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}>
          {data?.data.content.map((ad) => (
            <li
              key={ad.id}
              onClick={() => openDetail(ad.id || 0)}
              className="flex items-center justify-between gap-x-6 py-5 cursor-pointer sm:px-6 md:px-8 lg:px-8"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {ad.name}
                  </p>
                  <p className={`${statusClasses[getStatus(ad)]} mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset`}>
                    {getStatus(ad).toString()}
                  </p>
                </div>
                {ad.campaign && <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <NavLink to={`/campaigns/${ad.campaign.id}`} className="whitespace-nowrap">
                    {ad.campaign.name}
                  </NavLink>
                </div>}
              </div>
              <div className="min-w-0">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {/* TODO internationalize */
                    format(ad.startDate, "dd.MM.yyyy") + " - " + format(ad.endDate, "dd.MM.yyyy.")
                  }
                </p>
              </div>
              <div className="flex flex-none items-center gap-x-4"></div>
            </li>
          ))}
        </ul>
      </Paged>
    </>
  );
};

export default AdsList;

export function loader({ params }: LoaderFunctionArgs) {
  const pageNumber = params.pageNumber ? Number(params.pageNumber) - 1 : 0;
  return queryClient.fetchQuery({
    queryKey: ["ads", pageNumber, DEFAULT_PAGE_SIZE],
    queryFn: () => adService.list(pageNumber, DEFAULT_PAGE_SIZE),
  });
}
