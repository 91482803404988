import placementService from "../../../api/placement.service";
import { DEFAULT_PAGE_SIZE, queryClient } from "../../../api/common";
import { useQuery } from "@tanstack/react-query";
import Paged from "../../../components/pagination/Paged";
import { LoaderFunctionArgs, useNavigate, useParams } from "react-router-dom";
import Drawer from "../../../components/drawer/Drawer";
import { useState } from "react";
import PlacementForm from "./PlacementForm";
import { useTopbarSelection } from "../../../store/topbar-selection-context";

const PlacementsList = () => {
  const params = useParams();
  const pageNumber = params.pageNumber ? Number(params.pageNumber) : 1;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const openCreateDrawer = () => setOpen(true);
  const closeCreateDrawer = () => setOpen(false);

  const website = useTopbarSelection()?.selectedWebsite;

  const { data, isPending, isLoading, isRefetching } = useQuery({
    queryKey: [
      "placements",
      "website",
      website?.id,
      pageNumber - 1,
      DEFAULT_PAGE_SIZE,
    ],
    queryFn: () =>
      placementService.list(website?.id, pageNumber - 1, DEFAULT_PAGE_SIZE),
  });

  const openDetail = (placementId: number) => {
    navigate(`/placements/${placementId}`);
  };

  return (
    <>
      <div className="mt-4 sm:flex sm:w-auto justify-end sm:px-6 md:px-8 lg:px-8">
        <button
          type="button"
          onClick={openCreateDrawer}
          className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Create
        </button>
      </div>
      <Drawer
        open={open}
        onClose={closeCreateDrawer}
        title="New placement"
        subtitle="Get started by filling in the information below to create your new placement."
      >
        <PlacementForm
          onCancel={closeCreateDrawer}
          onSubmit={closeCreateDrawer}
        />
      </Drawer>
      <Paged
        currentPage={pageNumber}
        pagesCount={data?.data.totalPages || 0}
        itemName="placement"
        totalItems={data?.data.totalElements || 0}
        className={`sm:px-6 md:px-8 lg:px-8 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}
      >
        <ul
          role="list"
          className={`divide-y divide-gray-100 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}
        >
          {data?.data.content.map((placement) => (
            <li
              key={placement.tag}
              onClick={() => openDetail(placement.id || 0)}
              className="flex items-center justify-between gap-x-6 py-5 cursor-pointer sm:px-6 md:px-8 lg:px-8"
            >
              <div className="min-w-0 flex items-center">
                <img
                  className="mr-4 invisible md:visible"
                  src={`/img/placement/${placement.category.toLowerCase()}.svg`}
                  alt={placement.category}
                />
                <div>
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {placement.name}
                    </p>
                    <p className="text-green-700 bg-green-50 ring-green-600/20 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                      Active
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                      {placement.description?.text}
                    </p>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">
                      {placement.description?.tags.join(", ")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4"></div>
            </li>
          ))}
        </ul>
      </Paged>
    </>
  );
};

export default PlacementsList;

export function loader({ params }: LoaderFunctionArgs) {
  const pageNumber = params.pageNumber ? Number(params.pageNumber) - 1 : 0;
  return queryClient.fetchQuery({
    queryKey: ["placements", pageNumber, DEFAULT_PAGE_SIZE],
    queryFn: () => placementService.list(pageNumber, DEFAULT_PAGE_SIZE),
  });
}
