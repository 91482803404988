import { Ad } from "../../../models/api/ad.api";

const AdDetailAdStatusBadge: React.FC<{ ad: Ad }> = ({ ad }) => {
  let status: string;

  if (!ad.targets || ad.targets.length === 0) {
    status = "draft";
  } else if (ad.startDate < new Date() && ad.endDate > new Date()) {
    status = "active";
  } else if (ad.endDate < new Date()) {
    status = "expired";
  } else {
    status = "scheduled";
  }

  const statusClasses: Record<string, string> = {
    draft: "bg-gray-100 text-gray-600 ring-gray-500/10",
    active: "bg-green-100 text-green-700 ring-green-600/20",
    expired: "bg-red-100 text-red-700 ring-red-600/10",
    scheduled: "bg-yellow-100 text-yellow-800 ring-yellow-600/20",
  };

  const statusFill: Record<string, string> = {
    draft: "fill-gray-400",
    active: "fill-green-500",
    expired: "fill-red-500",
    scheduled: "fill-yellow-500",
  };

  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusClasses[status]}`}
    >
      <svg
        viewBox="0 0 6 6"
        aria-hidden="true"
        className={`h-1.5 w-1.5 ${statusFill[status]}`}
      >
        <circle r={3} cx={3} cy={3} />
      </svg>
      {status}
    </span>
  );
};

export default AdDetailAdStatusBadge;
