import axios, { AxiosRequestHeaders, AxiosRequestTransformer } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { getAuthToken } from "../util/auth";

export const DEFAULT_PAGE_SIZE = 5;

const dateTransformer: AxiosRequestTransformer = (
  data: any,
  headers: AxiosRequestHeaders
): any => {
  if (data instanceof Date) {
    return format(data, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  }

  return data;
};

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/",
  headers: {
    "Content-type": "application/json",
  },
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

http.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default http;

export const queryClient = new QueryClient();
