import http from "./common";
import Placement from "../models/api/placement.api";
import Paged from "../models/api/paged.api";

class PlacementService {
    list(websiteId?: number, page: number = 0, size: number = 20) {
        return http.get<Paged<Placement>>(`/placements?page=${page}&size=${size}&sort=id,desc${websiteId ? `&website.id=${websiteId}` : ""}`);
    }

    single(id: number) {
        return http.get<Placement>(`/placements/${id}`);
    }

    save(placement: Placement) {
        if (placement.id) {
            return http.put<Placement>(`/placements/${placement.id}`, placement);
        } else {
            return http.post<Placement>(`/placements`, placement);
        }
    }
}

export default new PlacementService();