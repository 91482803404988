import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { TreeItemProps, TreeNode } from "./TreeView";
import { MinusIcon } from "@radix-ui/react-icons";
import { PlusIcon } from "@heroicons/react/20/solid";
import { LoadingSpinner } from "../LoadingSpinner";
import { cn } from "../../lib/shadcn/utils";

const TreeItem: React.FC<TreeItemProps> = ({
  node,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: children,
    isLoading: isChildrenLoading,
    isFetched,
    error,
    refetch,
  } = useQuery<TreeNode[]>({
    queryKey: node.childrenQueryOptions?.queryKey ?? [
      "fetchChildren",
      node.value,
    ],
    queryFn: async () => {
      if (node.childrenQueryOptions?.queryFn) {
        return await node.childrenQueryOptions?.queryFn();
      }
      return [];
    },
    enabled: false,
    initialData: node.children,
  });

  const toggleOpen = async () => {
    if (!isOpen) {
      if (!children || children.length === 0) {
        await refetch(); // trigger the fetch when opening if children are not loaded
      }
    }
    setIsOpen((isOpen) => !isOpen);
  };

  const hasChildren = !(((node.childrenQueryOptions?.queryFn &&
    isFetched) || (!node.childrenQueryOptions?.queryFn)) &&
    children?.length == 0);

  return (
    <div className="pl-2 w-full">
      <div className="flex flex-row items-center justify-between w-full">
        <div
          onClick={() => hasChildren && toggleOpen()}
          className={cn(hasChildren ? "cursor-pointer" : "cursor-default", "flex items-center text-sm font-medium text-gray-900 mb-1")}
        >
          <div className="mr-2 text-gray-400">
            {isChildrenLoading && <LoadingSpinner className="w-4 h-4" />}
            {!isChildrenLoading &&
              ((node.childrenQueryOptions?.queryFn && !isFetched) ||
                (children && children.length > 0)) &&
              (isOpen ? (
                <MinusIcon className="w-4 h-4" />
              ) : (
                <PlusIcon className="w-4 h-4" />
              ))}
          </div>
          {node.label}
        </div>
      </div>

      {error && <div className="text-red-500">Error loading children</div>}
      {isOpen && isFetched && children && children.length > 0 && (
        <div className="ml-2 -mt-2 pb-2 pt-4 mb-2 border-l border-gray-200">
          {children.map((child, index) => (
            <TreeItem
              key={child.value.id}
              node={child}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeItem;
