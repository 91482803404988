// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glitch {
  position: relative;
  display: inline-block;
  animation: glitch 1s linear infinite;
}

.glitch:before,
.glitch:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: inherit;
}

.glitch:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

.glitch:after {
  animation: glitchBottom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 2px) skew(0deg);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchBottom {
  2%,
  64% {
    transform: translate(-2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(2px, 0) skew(0deg);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/404/glitch-404.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oCAAoC;AACtC;;AAEA;;EAEE,wBAAwB;EACxB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,uCAAuC;EACvC,gDAAgD;EAChD,wDAAwD;AAC1D;;AAEA;EACE,4CAA4C;EAC5C,sDAAsD;EACtD,8DAA8D;AAChE;;AAEA;EACE;;IAEE,uCAAuC;EACzC;EACA;;IAEE,wCAAwC;EAC1C;EACA;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;;IAEE,0CAA0C;EAC5C;EACA;;IAEE,0CAA0C;EAC5C;EACA;IACE,6CAA6C;EAC/C;AACF;;AAEA;EACE;;IAEE,wCAAwC;EAC1C;EACA;;IAEE,uCAAuC;EACzC;EACA;IACE,4CAA4C;EAC9C;AACF","sourcesContent":[".glitch {\n  position: relative;\n  display: inline-block;\n  animation: glitch 1s linear infinite;\n}\n\n.glitch:before,\n.glitch:after {\n  content: attr(data-text);\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  color: inherit;\n}\n\n.glitch:before {\n  animation: glitchTop 1s linear infinite;\n  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);\n  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);\n}\n\n.glitch:after {\n  animation: glitchBottom 1.5s linear infinite;\n  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);\n  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);\n}\n\n@keyframes glitch {\n  2%,\n  64% {\n    transform: translate(2px, 0) skew(0deg);\n  }\n  4%,\n  60% {\n    transform: translate(-2px, 0) skew(0deg);\n  }\n  62% {\n    transform: translate(0, 0) skew(5deg);\n  }\n}\n\n@keyframes glitchTop {\n  2%,\n  64% {\n    transform: translate(2px, -2px) skew(0deg);\n  }\n  4%,\n  60% {\n    transform: translate(-2px, 2px) skew(0deg);\n  }\n  62% {\n    transform: translate(13px, -1px) skew(-13deg);\n  }\n}\n\n@keyframes glitchBottom {\n  2%,\n  64% {\n    transform: translate(-2px, 0) skew(0deg);\n  }\n  4%,\n  60% {\n    transform: translate(2px, 0) skew(0deg);\n  }\n  62% {\n    transform: translate(-22px, 5px) skew(21deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
