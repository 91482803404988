import { useState } from "react";
import UIPropsBase from "../../models/ui/props";
import Paginator from "./Paginator";
import LoadingIndicator from "../LoadingIndicator";
import NoItems from "./NoItems";

const Paged: React.FC<
UIPropsBase & {
  pagesCount: number;
  currentPage: number;
  totalItems: number;
  createUrl?: string;
  className?: string;
  loading?: boolean;
  itemName: string;
  onPageChange?: (page: number) => void;
}> = ({
  children,
  pagesCount,
  currentPage,
  totalItems = 0,
  createUrl,
  className,
  loading,
  itemName,
  onPageChange,
}) => {
  return (
    <>
      {loading && <LoadingIndicator />}
      {!loading && children && (
        <>
          {totalItems == 0 && createUrl && <NoItems itemName={itemName} createUrl={createUrl} />}
          {totalItems > 0 && children}
        </>
      )}
      {pagesCount > 1 && (
            <Paginator
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
              className={className}
            />
          )}
    </>
  );
};

export default Paged;
