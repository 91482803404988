import http from "./common";
import Paged from "../models/api/paged.api";
import { Ad } from "../models/api/ad.api";
import { AdResponse } from "../models/api/ad-response.api";

class AdService {
  list(
    page: number = 0,
    size: number = 20,
    placementId?: number,
    websiteId?: number
  ) {
    const queryParams: string[] = [`page=${page}&size=${size}`];
    if (placementId) {
      queryParams.push(`placement.id=${placementId}`);
    }
    if (websiteId) {
      queryParams.push(`website.id=${websiteId}`);
    }
    return http.get<Paged<Ad>>(`/ads?${queryParams.join("&")}`);
  }

  single(id: number) {
    return http.get<Ad>(`/ads/${id}`);
  }

  image(id: number) {
    return http.get<AdResponse>(`/ads/${id}/image`);
  }

  create(ad: Ad, file: File) {
    const formData = new FormData();

    formData.append(
      "ad",
      new Blob([JSON.stringify(ad)], {
        type: "application/json",
      })
    );
    formData.append("file", file);

    return http.post<Ad>("/ads", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(ad: Ad) {
    if (ad.id) {
      return http.put<Ad>(`/ads/${ad.id}`, ad);
    }
  }
}

export default new AdService();
