import { forwardRef, useState } from "react";

const DEFAULT_INPUT_CLASSES =
  "block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
const DEFAULT_VALUE_CLASSES = "px-2 py-1.5 text-gray-900";

type DisplayaleInputProps = {
  name: string;
  isEditing: boolean;
  label?: string;
  value?: string;
  inputClassName?: string;
  valueClassName?: string;
};

const DisplayableInput = forwardRef<HTMLInputElement, DisplayaleInputProps>(
  (
    {
      name,
      isEditing = false,
      label,
      value,
      inputClassName = DEFAULT_INPUT_CLASSES,
      valueClassName = DEFAULT_VALUE_CLASSES,
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState<string>(value || "");

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(event.target.value);
    };

    return (
      <>
        <div className={`${isEditing ? "" : "hidden"}`}>
          {label && (
            <label
              htmlFor={name}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {label}
            </label>
          )}
          <div className={`${label ? "mt-2" : ""}`}>
            <input
              ref={ref}
              onChange={onChange}
              name={name}
              className={inputClassName}
              value={currentValue}
            />
          </div>
        </div>
        <div className={`${isEditing ? "hidden" : ""}`}>
          <div className={valueClassName}>{currentValue}</div>
        </div>
      </>
    );
  }
);

export default DisplayableInput;
