const Tooltip: React.FC<{ text?: string, className?: string }> = ({ text, className }) => {
  return (
      <div className="relative group">
        <svg
          className="shrink-0 size-4 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
          <path d="M12 17h.01" />
        </svg>
        <span
          className={`${className ? className : ""} w-64 opacity-0 transition-opacity duration-300 inline-block absolute z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm transform bottom-5 pointer-events-none group-hover:opacity-90`}
          role="tooltip"
        >
          {text}
        </span>
      </div>
  );
};

export default Tooltip;
