import React, { useState } from "react";

const ChipsInput: React.FC<{name: string}> = ({name}) => {
  const [chips, setChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleAddChip = () => {
    if (inputValue.trim() && !chips.includes(inputValue.trim())) {
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveChip = (chip: string) => {
    setChips(chips.filter((c) => c !== chip));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddChip();
    }
  };

  return (
    <div className="flex flex-wrap items-center rounded-md border-0 pt-1.5 pl-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600">
      {chips.map((chip, index) => (
        <span
          key={chip}
          className="mr-1.5 mb-1.5 inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
        >
          {chip}
          <button
            type="button"
            onClick={() => handleRemoveChip(chip)}
            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
          >
            <span className="sr-only">{chip}</span>
            <svg
              viewBox="0 0 14 14"
              className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
            >
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span className="absolute -inset-1" />
          </button>
        </span>
      ))}
      <input type="hidden" name={name} value={chips.join(",")} />
      <input
        className="flex-grow p-1 mb-1.5 mr-1.5 text-sm focus:outline-none border-none"
        id={name}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type and press enter..."
      />
    </div>
  );
};

export default ChipsInput;
