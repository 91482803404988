import { useState } from "react";
import { Field, Label, Switch } from "@headlessui/react";
import MultiStateToggle, {
  StateProps,
} from "../../../../components/MultiStateToggle";
import { CheckIcon, StarIcon } from "@heroicons/react/24/outline";
import websiteService from "../../../../api/website.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import TreeView, { TreeNode } from "../../../../components/tree/TreeView";
import Paged from "../../../../models/api/paged.api";
import { Website } from "../../../../models/api/website.api";
import placementService from "../../../../api/placement.service";
import Placement from "../../../../models/api/placement.api";
import WebsiteIcon from "../../../../components/WebsiteIcon";
import { PriceType } from "../../../../models/api/price-type.api";
import { cn } from "../../../../lib/shadcn/utils";
import { Price } from "../../../../models/api/price.api";
import { pl } from "date-fns/locale";
import { useNavigate, useParams } from "react-router-dom";
import adService from "../../../../api/ad.service";
import AdSetupTargetingStepSummary from "./AdSetupTargetingStepSummary";
import { Ad } from "../../../../models/api/ad.api";
import { AdTarget } from "../../../../models/api/ad-target.api";
import { queryClient } from "../../../../api/common";

const PLACEMENT_TARGETING_STATES = {
  standard: {
    type: PriceType.STANDARD,
    value: "standard",
    label: "Standard",
    tooltip:
      "When an ad is standard it will rotate with all other active standard ads that are targeting this placement.",
    icon: CheckIcon,
    bgColor: "bg-blue-600",
    selectedIconColor: "text-blue-600",
    unselectedIconColor: "text-blue-400",
  },
  exclusive: {
    type: PriceType.EXCLUSIVE,
    value: "exclusive",
    label: "Exclusive",
    tooltip:
      "When an ad is exclusive no other ad will be shown, as long as it is active.",
    icon: StarIcon,
    bgColor: "bg-amber-600",
    selectedIconColor: "text-amber-600",
    unselectedIconColor: "text-amber-400",
  },
};

async function attachTargetings(
  placements: { placement: Placement; price: Price }[],
  ad?: Ad,
) {
  if (ad) {
    const targetings: AdTarget[] = placements.map(({ placement, price }) => {
      return {
        placement: placement,
        priceType: price.type,
      };
    });

    const clonedAd = { ...ad };
    clonedAd.targets = targetings;

    // TODO validation
    const resp = await adService.update(clonedAd);

    // TODO error handling
    return resp?.data;
  }
}
const AdSetupTargetingStep: React.FC = () => {
  const navigate = useNavigate();
  const [ownedWebsitesOnly, setOwnedWebsitesOnly] = useState(true);
  const scope = ownedWebsitesOnly ? "owned" : "all";
  const [selectedPlacements, setSelectedPlacements] = useState<
    { placement: Placement; price: Price }[]
  >([]);

  const { adId } = useParams();

  // TODO error handling
  const { data: ad } = useQuery({
    queryKey: ["ads", adId],
    queryFn: async () => adService.single(Number(adId)),
  });

  const { data: websites, isPending: websitesArePending } = useQuery({
    queryKey: ["websites", scope],
    queryFn: async () => websiteService.list(scope),
  });

  // TODO error handling
  const { mutate, isPending } = useMutation({
    mutationFn: () => attachTargetings(selectedPlacements, ad?.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["ads", adId] });
      data && navigate(`/ads/setup/${data?.id}/payment`);
    },
  });

  const handleSubmit = () => {
    mutate();
  };

  const handleOnChange = (placement: Placement, price?: Price) => {
    setSelectedPlacements((prev) => {
      const filtered = prev.filter((p) => p.placement.id !== placement.id);
      if (price) {
        filtered.push({ placement, price });
      }
      return filtered;
    });
  };
  const placementsToTreeItems = (placements: Paged<Placement>): TreeNode[] => {
    return placements.content.map((placement) => {
      return {
        label: (
          <PlacementLabel placement={placement} onChange={handleOnChange} />
        ),
        value: placement,
      };
    });
  };

  const websitesToTreeItems = (websites: Paged<Website>): TreeNode[] => {
    return websites.content.map((website) => {
      return {
        label: <WebsiteLabel website={website} />,
        value: website,
        childrenQueryOptions: {
          queryKey: ["placements", "website", website.id],
          queryFn: async () =>
            placementsToTreeItems(
              (await placementService.list(website.id)).data
            ),
        },
      };
    });
  };

  return (
    <div className="bg-white min-h-full">
      <div className="flex flex-col min-h-full">
        <div className="w-full">
          <OwnedWebsitesSwitch
            value={ownedWebsitesOnly}
            onChange={(enabled) => setOwnedWebsitesOnly(enabled)}
          />
        </div>
        <div className="flex md:flex-row flex-col">
          <div className="flex-1 min-h-full">
            <TreeView
              items={
                websites && !websitesArePending
                  ? websitesToTreeItems(websites?.data)
                  : []
              }
            />
          </div>
          <div className="flex-1 min-h-full">
            {ad && (
              <AdSetupTargetingStepSummary
                onSubmit={handleSubmit}
                ad={ad?.data}
                placements={selectedPlacements}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WebsiteLabel: React.FC<{ website: Website }> = ({ website }) => {
  return (
    <div className="min-w-0 flex flex-col items-start gap-y-0">
      <p className="text-sm font-semibold text-gray-900">{website.name}</p>
      <div className="flex min-w-0 gap-x-1 items-center">
        <WebsiteIcon website={website.url} className="w-4 h-4" />
        <p className="truncate text-xs text-gray-500">{website.url}</p>
      </div>
    </div>
  );
};

const PlacementLabel: React.FC<{
  placement: Placement;
  onChange: (placement: Placement, price?: Price) => void;
}> = ({ placement, onChange }) => {
  const standardPrice = placement.price?.find(
    (p) => p.type === PriceType.STANDARD
  );

  const exclusivePrice = placement.price?.find(
    (p) => p.type === PriceType.EXCLUSIVE
  );
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(0);

  const priceOptions: (typeof PLACEMENT_TARGETING_STATES.standard)[] = [];
  standardPrice && priceOptions.push(PLACEMENT_TARGETING_STATES.standard);
  exclusivePrice && priceOptions.push(PLACEMENT_TARGETING_STATES.exclusive);

  const getSelectedPrice = (index: number) => {
    // minus 1 since 0 == off
    return index == 0
      ? undefined
      : priceOptions[index - 1].type == PriceType.STANDARD
        ? standardPrice
        : exclusivePrice;
  };

  const selectedPrice = getSelectedPrice(selectedPriceIndex);

  const onSelectedPriceChange = (index: number) => {
    setSelectedPriceIndex(index);
    onChange?.(placement, getSelectedPrice(index));
  };

  const selectPrice = (type: PriceType) => {
    const index = priceOptions.findIndex((p) => p.type == type);
    if (index > -1) {
      onSelectedPriceChange(index + 1);
    }
  };

  return (
    <div className="min-w-0 flex flex-row gap-x-2 justify-between w-full items-center">
      <div className="min-w-0 flex flex-col items-start gap-y-0">
        <p className="text-sm font-semibold text-gray-900">{placement.name}</p>
        <p className="truncate text-xs text-gray-500">{placement.category}</p>
      </div>
      <div className="font-extralight text-5xl text-gray-200">/</div>
      <div className="min-w-0 flex flex-col items-start gap-y-0">
        {/* TODO: internalize */}
        <p
          onClick={() => selectPrice(PriceType.STANDARD)}
          className={cn(
            standardPrice && "cursor-pointer hover:text-blue-500",
            selectedPrice?.type == PriceType.STANDARD
              ? "text-blue-600"
              : selectedPrice
                ? "text-gray-200"
                : "text-gray-500",
            "truncate text-sm font-semibold transition-colors"
          )}
        >
          {standardPrice ? "RSD " + standardPrice.value + " /standard" : " - "}
        </p>
        <p
          onClick={() => selectPrice(PriceType.EXCLUSIVE)}
          className={cn(
            exclusivePrice && "cursor-pointer hover:text-blue-500",
            selectedPrice?.type == PriceType.EXCLUSIVE
              ? "text-blue-600"
              : selectedPrice
                ? "text-gray-200"
                : "text-gray-500",
            "truncate text-sm font-semibold  transition-colors"
          )}
        >
          {exclusivePrice
            ? "RSD " + exclusivePrice.value + " /exclusive"
            : " - "}
        </p>
      </div>
      {priceOptions.length > 0 && (
        <PlacementTargetSelector
          value={selectedPriceIndex}
          onChange={onSelectedPriceChange}
          states={priceOptions}
        />
      )}
    </div>
  );
};

const PlacementTargetSelector: React.FC<{
  onChange: (input: any) => void;
  states: StateProps[];
  value?: number;
}> = ({ value = 0, onChange, states }) => {
  const handleChangeState = (index: number) => {
    onChange?.(index);
  };

  return (
    <MultiStateToggle
      onChange={handleChangeState}
      classNames="ml-4"
      states={states}
      value={value}
    />
  );
};

const OwnedWebsitesSwitch: React.FC<{
  value: boolean;
  onChange: (enabled: boolean) => void;
}> = ({ value = true, onChange }) => {
  const [enabled, setEnabled] = useState(value);

  return (
    <Field className="flex items-center mb-8">
      <Switch
        checked={enabled}
        onChange={(enabled) => {
          setEnabled(enabled);
          onChange(enabled);
        }}
        className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 data-[checked]:bg-blue-600"
      >
        <span
          aria-hidden="true"
          className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
      </Switch>
      <Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">Only websites I own</span>{" "}
      </Label>
    </Field>
  );
};

export default AdSetupTargetingStep;
