import { useRef } from "react";
import { Website } from "../../models/api/website.api";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import websiteService from "../../api/website.service";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../api/common";
import { AxiosResponse } from "axios";

const AddEditWebsiteForm: React.FC<{
  website: Website | null;
  onSubmit?: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}> = ({ website, onSubmit, onSuccess, onCancel }) => {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const urlRef = useRef<HTMLInputElement | null>(null);

  // TODO error handling
  const { mutate, isPending } = useMutation({
    mutationFn: (vars) => {
      onSubmit && onSubmit();

      const data = {
        id: website?.id,
        name: nameRef.current?.value || "",
        url: urlRef.current?.value || "",
      };

      return websiteService.save(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["websites"] });
      onSuccess && onSuccess();
    },
  });

  return (
    <>
      <form className="flex flex-row" method="POST">
        <div className="space-x-6 pr-5 pl-2">
          <div>
            <label
              htmlFor="website-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                ref={nameRef}
                id="website-name"
                name="website-name"
                defaultValue={website?.name}
                type="text"
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="space-x-6 pr-5 pl-2">
          <div>
            <label
              htmlFor="website-url"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              URL
            </label>
            <div className="mt-2">
              <input
                ref={urlRef}
                id="website-url"
                defaultValue={website?.url}
                name="website-url"
                type="text"
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="space-x-6 pr-5 align-bottom content-end">
          <button
            disabled={isPending}
            onClick={(event) => {
              event.preventDefault();
              mutate();
            }}
            type="submit"
            className="min-w-24 ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-100 disabled:cursor-not-allowed transition-all duration-300"
          >
            {isPending ? (
              <Cog8ToothIcon className="animate-spin-slow h-5 w-5" />
            ) : (
              "Save"
            )}
          </button>
          <a
          className="text-sm font-semibold text-gray-400 hover:text-blue-500 hover:cursor-pointer hover:underline transition-all duration-100"
            onClick={(event) => {
              event.preventDefault();
              !isPending && onCancel && onCancel();
            }}
          >Cancel</a>
        </div>
      </form>
    </>
  );
};

export default AddEditWebsiteForm;
