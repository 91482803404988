import http from "./common";
import {Website} from "../models/api/website.api";
import Paged from "../models/api/paged.api";

class WebsiteService {
    list(scope: string = "owned", page: number = 0, size: number = 20) {
        return http.get<Paged<Website>>(`/websites?page=${page}&size=${size}&sort=id,desc&scope=${scope}`);
    }

    single(id: number) {
        return http.get<Website>(`/websites/${id}`);
    }

    delete(id: number) {
        return http.delete<Website>(`/websites/${id}`);
    }

    save(website: Website) {
        if (website.id) {
            return http.put<Website>(`/websites/${website.id}`, website);
        } else {
            return http.post<Website>(`/websites`, website);
        }
    }
}

export default new WebsiteService();