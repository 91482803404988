import {
  CheckIcon,
  Cog8ToothIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useRef, useState } from "react";
import {
  LoaderFunctionArgs,
  useLoaderData
} from "react-router-dom";
import authService from "../../api/auth.service";
import { queryClient } from "../../api/common";
import websiteService from "../../api/website.service";
import DisplayableInput from "../../components/DisplayableInput";
import LoadingIndicator from "../../components/LoadingIndicator";
import { User } from "../../models/api/user.api";
import { Website } from "../../models/api/website.api";
import AddEditWebsiteForm from "./AddEditWebsiteForm";
import SettingsWebsite from "./SettingsWebsite";
import userService from "../../api/user.service";

const Settings: React.FC = () => {
  const currentUser = useQuery({
    queryKey: ["me"],
    queryFn: () => authService.me(),
  });

  const [deletingIds, setDeletingIds] = useState([] as number[]);
  const [formWebsite, setFormWebsite] = useState<Website | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const fullNameRef = useRef<HTMLInputElement | null>(null);

  const websitesQuery = useQuery({
    queryKey: ["websites", "owned"],
    queryFn: () => websiteService.list(),
  });

  // TODO error handling
  const deleteMutation = useMutation({
    mutationFn: (vars: { id: number }) => websiteService.delete(vars.id),
    onSuccess: (website) => {
      queryClient.invalidateQueries({ queryKey: ["websites", "owned"] });
      setDeletingIds((old) => old.filter((id) => id !== website.data.id));
    },
  });

  // TODO error handling
  const updateProfileMutation = useMutation({
    mutationFn: (vars) => {
      const data = {
        id: currentUser?.data?.data.id || 0,
        fullName: fullNameRef.current?.value || "",
        email: ""
      };

      return userService.save(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
  const handleDelete = (id?: number) => {
    if (id && deletingIds.length == 0) {
      deleteMutation.mutate({ id });
      setDeletingIds((old) => [...old, id]);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <div className="sm:px-6 md:px-8 lg:px-8">
            <h2 className="flex flex-row text-base font-semibold leading-7 text-gray-900">
              <div>Profile</div>
              {!isEditing && !updateProfileMutation.isPending && (
                <PencilSquareIcon
                  className={`hover:text-blue-600 hover:cursor-pointer w-4 h-4 text-gray-700 ml-0.5`}
                  onClick={() => setIsEditing(true)}
                />
              )}
              {updateProfileMutation.isPending && <Cog8ToothIcon className="animate-spin-slow w-6 h-6 text-gray-700" />}
              {isEditing && (
                <>
                  <CheckIcon
                    className={`hover:text-green-600 hover:cursor-pointer w-4 h-4 text-gray-700 ml-0.5`}
                    onClick={() => {
                      updateProfileMutation.mutate();
                      setIsEditing(false);
                    }}
                  />
                  <XMarkIcon
                    className={`hover:text-red-600 hover:cursor-pointer w-4 h-4 text-gray-700 ml-0.5`}
                    onClick={() => setIsEditing(false)}
                  />
                </>
              )}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6 sm:px-6 md:px-8 lg:px-8">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 py-1.5">
                Full name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <DisplayableInput
                  ref={fullNameRef}
                  name="full-name"
                  value={currentUser?.data?.data.fullName}
                  isEditing={isEditing}
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 py-1.5">
                Email address
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900 px-2 py-1.5">{currentUser?.data?.data.email}</div>
              </dd>
            </div>
          </dl>
        </div>

        <div>
          <div className="sm:px-6 md:px-8 lg:px-8">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Websites
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Add websites that you own or manage.
            </p>
          </div>
          {websitesQuery.isPending && <LoadingIndicator />}
          {!websitesQuery.isPending && (
            <>
              <ul
                role="list"
                className={`${websitesQuery.isRefetching || deleteMutation.isPending ? "animate-pulse" : ""} mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6 sm:px-6 md:px-8 lg:px-8`}
              >
                {websitesQuery.data?.data?.content?.map((website) => (
                  <li
                    className="flex justify-between gap-x-6 py-5 relative"
                    key={website.id}
                  >
                    <SettingsWebsite key={website.id} website={website} />
                    <div className="flex flex-row justify-center items-center align-middle">
                      {deletingIds.find((id) => id === website.id) && (
                        <Cog8ToothIcon className="animate-spin-slow w-6 h-6 text-gray-700" />
                      )}
                      {!deletingIds.find((id) => id === website.id) && (
                        <>
                          <PencilSquareIcon
                            className={`${deleteMutation.isPending ? "" : "hover:text-blue-600 hover:cursor-pointer"} w-6 h-6 text-gray-700`}
                            onClick={() => setFormWebsite(website)}
                          />
                          <XMarkIcon
                            className={`${deleteMutation.isPending ? "" : "hover:text-red-700 hover:cursor-pointer"} w-6 h-6 text-gray-700 ml-2`}
                            onClick={() => handleDelete(website.id)}
                          />
                        </>
                      )}
                    </div>
                  </li>
                ))}
              </ul>

              <div className="flex border-t border-gray-100 pt-6 sm:px-6 md:px-8 lg:px-8">
                {formWebsite && (
                  <AddEditWebsiteForm
                    website={formWebsite}
                    onCancel={() => setFormWebsite(null)}
                    onSuccess={() => setFormWebsite(null)}
                  />
                )}
                {!formWebsite && (
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500"
                    onClick={() => setFormWebsite({} as Website)}
                  >
                    <span aria-hidden="true">+</span> Add website
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export async function loader({ params }: LoaderFunctionArgs) {
  return queryClient.fetchQuery({
    queryKey: ["me"],
    queryFn: () => authService.me(),
  });
}

export default Settings;
