import { useEffect, useState } from "react";

function gcd(a: number, b: number): number {
  return b === 0 ? a : gcd(b, a % b);
}

const AdDetailCreativePreview: React.FC<{ image: string }> = ({ image }) => {
  const [imageInfo, setImageInfo] = useState({
    width: 0,
    height: 0,
    sizeKB: 0,
    aspectRatio: "0/0",
  });

  const getImageInfo = (imageBase64: string) => {
    // Calculate the size in bytes
    const sizeInBytes =
      imageBase64.length * (3 / 4) - (imageBase64.endsWith("==") ? 2 : 1) * 2;
    const sizeKB = sizeInBytes / 1024;

    // Create a new Image object
    const img = new Image();
    img.src = imageBase64;

    img.onload = () => {
      const { width, height } = img;
      const gcdValue = gcd(width, height);
      const aspectRatio = `${width / gcdValue} / ${height / gcdValue}`;
      setImageInfo({ width, height, sizeKB, aspectRatio });
    };
  };

  useEffect(() => {
    if (image) {
      getImageInfo(image);
    }
  }, [image]);
  return (
    <div className="flex space-x-8 p-3 border rounded-md w-fit">
      <img
        src={image}
        alt="File Preview"
        className="rounded-md"
        style={{ aspectRatio: imageInfo.aspectRatio, objectFit: "cover", maxWidth: "300px" }}
      />
      <div className="flex flex-col items-start justify-center space-y-3 whitespace-nowrap">
        <div className="grid gap-2 text-sm text-gray-600">
          <div className="flex items-center justify-between space-x-4">
            <span>Ratio:</span>
            <span>{imageInfo.aspectRatio}</span>
          </div>
          <div className="flex items-center justify-between space-x-4">
            <span>Dimensions:</span>
            <span>{imageInfo.width} x {imageInfo.height}</span>
          </div>
          <div className="flex items-center justify-between space-x-4">
            <span>File size:</span>
            <span>{imageInfo.sizeKB.toFixed(2)} KB</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdDetailCreativePreview;
