import React from "react";

interface PaymentSlipProps {
  payerName: string;
  payerAddress: string;
  payerCity: string;
  recipientName: string;
  recipientAccount: string;
  paymentPurpose: string;
  paymentAmount: string;
  referenceNumber: string;
  model: string;
  recipientCity: string;
}

const SerbianPaymentSlip: React.FC<PaymentSlipProps> = ({
  payerName,
  payerAddress,
  payerCity,
  recipientName,
  recipientAccount,
  paymentPurpose,
  paymentAmount,
  referenceNumber,
  model,
  recipientCity,
}) => {
  return (
    <div className="max-w-2xl mx-auto bg-white border rounded-md p-4 shadow-sm">
      <div className="flex flex-col w-full">
        <div className="flex flex-row-reverse items-end mb-4 w-full">
          <div className="text-lg font-semibold uppercase text-gray-900">Nalog za uplatu</div>
        </div>
        <div className="flex flex-row space-x-8">
          <div className="flex flex-col flex-1 space-y-4 mb-2">
            <div>
              <div className="text-xs font-semibold text-gray-900">UPLATILAC</div>
              <div className="text-gray-900 text-xs border rounded-md p-2 h-24 min-h-16">
                <div>{payerName}</div>
                <div>{payerAddress}</div>
                <div>{payerCity}</div>
              </div>
            </div>
            <div>
              <div className="text-xs font-semibold text-gray-900">SVRHA UPLATE</div>
              <div className="text-gray-900 text-xs border rounded-md p-2 min-h-16">
                <div>{paymentPurpose}</div>
              </div>
            </div>
            <div>
              <div className="text-xs font-semibold text-gray-900">PRIMALAC</div>
              <div className="text-gray-900 text-xs border rounded-md p-2 h-24 min-h-16">
                <div>{recipientName}</div>
                <div>{recipientCity}</div>
              </div>
            </div>
          </div>

          <div className="flex-1 flex-col space-y-4">
            <div className="flex flex-row space-x-4 items-end">
            <div className="flex-1">
              <div className="text-xs font-semibold text-gray-900">ŠIFRA UPLATE</div>
              <div className="border rounded-md p-2">
                <div>189</div>
              </div>
            </div>
            <div className="flex-grow">
              <div className="text-xs font-semibold text-gray-900">VALUTA</div>
              <div className="border rounded-md p-2">
                <div>RSD</div>
              </div>
            </div>
            <div className="flex-grow-[3]">
              <div className="text-xs font-semibold text-gray-900">IZNOS</div>
              <div className="border rounded-md p-2">{paymentAmount}</div>
            </div>
            </div>

            <div className="flex flex-row space-x-4 items-end">
            <div className="flex-grow-[5]">
              <div className="text-xs font-semibold text-gray-900">RAČUN PRIMAOCA</div>
              <div className="border rounded-md p-2">
                <div>{recipientAccount}</div>
              </div>
            </div>
            </div>

            <div className="flex flex-row space-x-4 items-end">
            <div className="flex-grow">
              <div className="text-xs font-semibold text-gray-900">MODEL</div>
              <div className="border rounded-md p-2">
                <div>{model}</div>
              </div>
            </div>
            <div className="flex-grow-[4]">
              <div className="text-xs font-semibold text-gray-900">POZIV NA BROJ</div>
              <div className="border rounded-md p-2">
                <div>{referenceNumber}</div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerbianPaymentSlip;
