import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { cn } from "../lib/shadcn/utils";

const WebsiteIcon: React.FC<{ website: string; className?: string }> = ({
  website,
  className,
}) => {
  const [imageError, setImageError] = useState(false);

  const cleanWebsite =
    website &&
    (website.startsWith("http://")
      ? website.substring(7)
      : website.startsWith("https://")
        ? website.substring(8)
        : website);
  return (
    <>
      {website && (
        <>
          {imageError && (
            <GlobeAltIcon
              className={cn(
                "h-12 w-12 flex-none rounded-full bg-transparent text-gray-400 shrink-0",
                className
              )}
            />
          )}
          {!imageError && (
            <img
              alt=""
              onError={() => setImageError(true)}
              src={`https://${cleanWebsite}/favicon.ico`}
              className={cn(
                "h-12 w-12 flex-none rounded-full bg-gray-50",
                className
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default WebsiteIcon;
