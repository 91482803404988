import { useEffect, useState } from "react";
import { DateRange } from "./DateRangePicker";
import { Button } from "../shadcn/button";
import { CheckIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/shadcn/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/select";

interface Preset {
  name: string;
  label: string;
}

// TODO localization
const PRESETS: Preset[] = [
  { name: "today", label: "Today" },
  { name: "next7", label: "Next 7 days" },
  { name: "next30", label: "Next 30 days" },
  { name: "thisWeek", label: "This Week" },
  { name: "nextWeek", label: "Next Week" },
  { name: "thisMonth", label: "This Month" },
  { name: "nextMonth", label: "Next Month" },
];

const DateRangePresets: React.FC<{
  selectedRange?: DateRange;
  onPresetClicked: (range?: DateRange) => void;
}> = ({ selectedRange, onPresetClicked }) => {
  const setSelectedPreset = (preset?: string) => {
    onPresetClicked(getPresetRange(preset));
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== "undefined" ? window.innerWidth < 960 : false
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPresetRange = (presetName?: string): DateRange | undefined => {
    const preset = PRESETS.find(({ name }) => name === presetName);
    if (!preset) return undefined;
    const from = new Date();
    const to = new Date();
    const first =
      from.getDate() - from.getDay() + (from.getDay() === 0 ? -6 : 1);
    const last = from.getDate() - (from.getDay() - 1) + 6;

    switch (preset.name) {
      case "today":
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case "next7":
        from.setHours(0, 0, 0, 0);
        to.setDate(to.getDate() + 6);
        to.setHours(23, 59, 59, 999);
        break;
      case "next30":
        from.setHours(0, 0, 0, 0);
        to.setDate(to.getDate() + 29);
        to.setHours(23, 59, 59, 999);
        break;
      case "thisWeek":
        from.setHours(0, 0, 0, 0);
        to.setDate(last);
        to.setHours(23, 59, 59, 999);
        break;
      case "nextWeek":
        from.setDate(first + 7);
        to.setDate(last + 7);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case "thisMonth":
        from.setHours(0, 0, 0, 0);
        to.setMonth(to.getMonth() + 1);
        to.setDate(0);
        to.setHours(23, 59, 59, 999);
        break;
      case "nextMonth":
        from.setMonth(from.getMonth() + 1);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setMonth(to.getMonth() + 2);
        to.setDate(0);
        to.setHours(23, 59, 59, 999);
        break;
    }

    return { from, to };
  };

  const getPresetRangeNormalized = (): string | undefined => {
    for (const preset of PRESETS) {
      const presetRange = getPresetRange(preset.name);

      const normalizedRangeFrom = new Date(selectedRange?.from || 0);
      normalizedRangeFrom.setHours(0, 0, 0, 0);
      const normalizedPresetFrom = new Date(
        presetRange?.from.setHours(0, 0, 0, 0) ?? 0
      );

      const normalizedRangeTo = new Date(selectedRange?.to || 0);
      normalizedRangeTo.setHours(0, 0, 0, 0);
      const normalizedPresetTo = new Date(
        presetRange?.to?.setHours(0, 0, 0, 0) ?? 0
      );

      if (
        normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
        normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
      ) {
        return preset.name;
      }
    }

    return undefined;
  };

  const PresetButton = ({
    preset,
    label,
    isSelected,
  }: {
    preset: string;
    label: string;
    isSelected: boolean;
  }): JSX.Element => (
    <Button
      className={cn(isSelected && "pointer-events-none text-blue-600")}
      variant="ghost"
      onClick={() => {
        setSelectedPreset(preset);
      }}
    >
      <>
        <span className={cn("pr-2 opacity-0", isSelected && "opacity-70")}>
          <CheckIcon width={18} height={18} />
        </span>
        {label}
      </>
    </Button>
  );

  const selected = getPresetRangeNormalized();

  return (
    <>
      {isSmallScreen && (
        <Select
          defaultValue={selected}
          value={selected}
          onValueChange={(value: string) => {
            setSelectedPreset(value);
          }}
        >
          <SelectTrigger className="w-[180px] mx-auto mb-2">
            <SelectValue placeholder="Select..." />
          </SelectTrigger>
          <SelectContent>
            {[{name: "none", label: "None"}, ...PRESETS].map((preset) => (
              <SelectItem key={preset.name} value={preset.name}>
                {preset.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}

      {!isSmallScreen && (
        <div className="flex flex-col items-end gap-1 pr-2 pl-6 pb-6">
          <div className="flex w-full flex-col items-end gap-1 pr-2 pl-6 pb-6">
            {PRESETS.map((preset) => (
              <PresetButton
                key={preset.name}
                preset={preset.name}
                label={preset.label}
                isSelected={selected === preset.name}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DateRangePresets;
