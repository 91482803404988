import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  ListboxButton,
} from "@headlessui/react";
import { ChevronDownIcon } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import websiteService from "../api/website.service";
import WebsiteIcon from "./WebsiteIcon";
import { Website } from "../models/api/website.api";
import { useTopbarSelection } from "../store/topbar-selection-context";
import React from "react";

const WebsiteSelector: React.FC = () => {
  const { selectedWebsite, setSelectedWebsite } = useTopbarSelection();

  const { data, isPending } = useQuery({
    queryKey: ["websites", "owned"],
    queryFn: () => websiteService.list(),
  });

  if (!isPending && (!selectedWebsite || !selectedWebsite.id)) {
    setSelectedWebsite(data?.data.content[0] || null);
  }

  return (
    <>
      {selectedWebsite && (
        <Listbox
          value={selectedWebsite}
          onChange={(website: Website) => {
            setSelectedWebsite(website);
          }}
        >
          <div className="relative min-w-96 flex md:flex-row-reverse">
            <ListboxButton className="group inset-y-0 right-0 p-2 flex border rounded items-center relative">
              <div className="flex min-w-0 gap-x-4">
                <WebsiteIcon
                  website={selectedWebsite?.url}
                  className="w-6 h-6"
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {selectedWebsite?.name}
                  </p>
                </div>
              </div>
              <ChevronDownIcon className="ml-3 size-4 text-gray-400 group-data-[hover]:fill-white" />
            </ListboxButton>

            {!isPending && (
              <ListboxOptions
                className="mt-11 max-h-96 min-w-48 overflow-auto transform-gpu scroll-py-3 overflow-y-auto p-3 absolute z-10 flex-auto bg-white border rounded shadow-sm"
                transition
              >
                {data?.data.content.map((website) => (
                  <ListboxOption
                    key={website.id}
                    value={website}
                    className="group relative flex cursor-pointer select-none rounded-xl p-3 data-[focus]:bg-gray-100 min-w-48"
                  >
                    <div className="flex gap-x-4 min-w-96">
                      <WebsiteIcon website={website.url} />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {website.name}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                          {website.url}
                        </p>
                      </div>
                    </div>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </div>
        </Listbox>
      )}
    </>
  );
}

export default WebsiteSelector;
