import WebsiteIcon from "../../components/WebsiteIcon";
import { Website } from "../../models/api/website.api";

const SettingsWebsite: React.FC<{ website: Website }> = ({ website }) => {
  return (
    <div className="flex min-w-0 gap-x-4">
      <WebsiteIcon website={website.url} />
      <div className="min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {website.name}
        </p>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
          {website.url}
        </p>
      </div>
    </div>
  );
};

export default SettingsWebsite;
