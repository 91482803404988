import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { Website } from "../models/api/website.api";

interface TopbarSelectionContextType {
  selectedWebsite: Website | null;
  setSelectedWebsite: (website: Website | null) => void;
  // TODO: date selector
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
}

const TopbarSelectionContext = createContext<TopbarSelectionContextType>({
  selectedWebsite: null,
  setSelectedWebsite: () => {},
  selectedStartDate: null,
  selectedEndDate: null,
});

export const TopbarSelectionContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectedWebsite, setSelectedWebsite] = useState<Website | null>(null);

  useEffect(() => {
    const storedWebsite = JSON.parse(
      localStorage.getItem("selectedWebsite") || "{}"
    ) as Website;
    storedWebsite && setSelectedWebsite(storedWebsite);
  }, []);

  useEffect(() => {
    console.log(JSON.stringify(selectedWebsite));
    selectedWebsite &&
      localStorage.setItem("selectedWebsite", JSON.stringify(selectedWebsite));
  }, [selectedWebsite]);

  return (
    <TopbarSelectionContext.Provider
      value={{
        selectedWebsite,
        setSelectedWebsite,
        selectedStartDate: null,
        selectedEndDate: null,
      }}
    >
      {children}
    </TopbarSelectionContext.Provider>
  );
};

// Custom hook to use the TopbarSelectionContext
export const useTopbarSelection = (): TopbarSelectionContextType => {
  const context = useContext(TopbarSelectionContext);
  if (!context) {
    throw new Error(
      "useTopbarSelection must be used within a TopbarSelectionContextProvider"
    );
  }
  return context;
};
