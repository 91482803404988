const StatsCard: React.FC<{
  title: string;
  unit?: string;
  value: number;
  prevValue?: number;
  tooltip?: string;
  index: number;
}> = ({ title, unit, value, prevValue, tooltip, index }) => {
  const positiveArrow = (
    <svg
      className="inline-block size-4 self-center"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
      <polyline points="16 7 22 7 22 13" />
    </svg>
  );
  const negativeArrow = (
    <svg
      className="inline-block size-4 self-center"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="22 17 13.5 8.5 8.5 13.5 2 7" />
      <polyline points="16 17 22 17 22 11" />
    </svg>
  );

  const diff = ((value - (prevValue || 0)) / (prevValue || 1)) * 100;

  return (
    <>
      <div
        key={title}
        className={`${index % 2 === 1 ? "sm:border-l" : index === 2 ? "lg:border-l" : ""} flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8`}
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">{title}</dt>
        {prevValue && (
          <dd
            className={`flex items-center gap-x-1 ${value < prevValue ? "text-red-700" : "text-green-700"} text-xs font-medium`}
          >
            {value < prevValue ? negativeArrow : positiveArrow}
            {diff}%
          </dd>
        )}
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {value}
        </dd>
      </div>
    </>
  );
};

export default StatsCard;
