import React from "react";
import { format, differenceInDays } from "date-fns";
import { ChevronRightIcon, MinusIcon } from "@heroicons/react/24/outline";
import Placement from "../../../../models/api/placement.api";
import { Price } from "../../../../models/api/price.api";
import { Ad } from "../../../../models/api/ad.api";
import { PriceType } from "../../../../models/api/price-type.api";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { MousePointerClickIcon } from "lucide-react";
import AdTargetingLabel from "../../../../components/AdTargetingLabel";

const AdSetupTargetingStepSummary: React.FC<{
  ad: Ad;
  placements: { placement: Placement; price: Price }[];
  onSubmit: () => void;
}> = ({ ad, placements, onSubmit }) => {
  // TODO internationalize
  const formatDate = (date: Date) => {
    return format(date, "dd.MM.yyyy");
  };

  const daysBetween = differenceInDays(ad.endDate, ad.startDate);

  const totalPrice =
    placements.reduce(
      (acc, { placement, price }) => acc + (price.value || 0),
      0
    ) * daysBetween;

  const standardPlacements = placements.filter(
    (p) => p.price.type === PriceType.STANDARD
  );

  const exclusivePlacements = placements.filter(
    (p) => p.price.type === PriceType.EXCLUSIVE
  );

  return (
    <div className="flex flex-col p-4 md:bg-gray-50 bg-white text-sm rounded-lg">
      <div className="w-full flex md:flex-row flex-col-reverse md:gap-0 gap-2 justify-between">
        <p className="mb-4 flex flex-row items-center border-b">
          {formatDate(ad.startDate)}
          <MinusIcon
            aria-hidden="true"
            className="h-3 w-3 flex-shrink-0 text-gray-400 mx-1"
          />
          {formatDate(ad.endDate)}
          <ChevronRightIcon
            aria-hidden="true"
            className="h-5 w-5 flex-shrink-0 text-gray-400"
          />
          {daysBetween} days
          <XMarkIcon
            aria-hidden="true"
            className="h-4 w-4 flex-shrink-0 text-gray-400 mx-1"
          />
        </p>
        <h3 className="text-lg font-semibold">Total: {totalPrice} RSD</h3>
      </div>
      <div className="w-full">
        {standardPlacements.length == 0 && exclusivePlacements.length == 0 && (
          <div className="w-full flex flex-col items-center">
            <MousePointerClickIcon className="mt-8 h-24 w-24 flex-shrink-0 text-gray-300 font-light" strokeWidth="1px" />
            <p className="text-gray-300">Please select placements to target</p>
          </div>
        )}
        {exclusivePlacements.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold w-full border-b mb-2">
              Exclusive
            </h3>
            {exclusivePlacements.map((p) => (
              <AdTargetingLabel
                className="pl-4"
                key={p.placement.id}
                target={ {placement: p.placement, priceType: PriceType.EXCLUSIVE} }
              />
            ))}
          </div>
        )}

        {standardPlacements.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold w-full border-b mb-2">
              Standard
            </h3>
            {standardPlacements.map((p) => (
              <AdTargetingLabel
                className="pl-4"
                key={p.placement.id}
                target={ {placement: p.placement, priceType: PriceType.STANDARD} }
              />
            ))}
          </div>
        )}
      </div>
      <div className="w-full flex md:flex-row-reverse flex-col md:items-center items-end mt-8">
        <button
          type="submit"
          onClick={onSubmit}
          className="min-w-24 ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-100 disabled:cursor-not-allowed transition-all duration-300"
        >
          Continue
        </button>
        <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
          You won't be charged until the next step.
        </p>
      </div>
    </div>
  );
};

export default AdSetupTargetingStepSummary;
