import ChipsInput from "../../../components/ChipsInput";
import Tooltip from "../../../components/Tooltip";
import Accordion from "../../../components/accordion/Accordion";
import AccordionTab from "../../../components/accordion/AccordionTab";
import { queryClient } from "../../../api/common";
import placementService from "../../../api/placement.service";
import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { PriceType } from "../../../models/api/price-type.api";
import {
  PlacementCategory,
  getPlacementCategory,
} from "../../../models/api/placement-category.api";
import Placement from "../../../models/api/placement.api";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, useRef, useState } from "react";
import { useTopbarSelection } from "../../../store/topbar-selection-context";

const PlacementForm: React.FC<{
  onCancel: () => void;
  onSubmit?: () => void;
}> = ({ onCancel, onSubmit }) => {
  const formRef = useRef(null);
  const website = useTopbarSelection()?.selectedWebsite;

  const savePlacement = async (data: FormData) => {
    if (website) {
      const placement: Placement = {
        id: data.get("id") ? Number(data.get("id")) : undefined,
        name: data.get("placement-name")?.toString() || "",
        description: {
          text: data.get("description")?.toString() || "",
          tags: data.get("tags")?.toString().split(",") || [],
        },
        category:
          getPlacementCategory(data.get("category")?.toString() || "") ||
          PlacementCategory.STANDALONE,
        // TODO get from context
        website: website,
        price: [
          {
            value: Number(data.get("price-standard")),
            type: PriceType.STANDARD,
          },
        ],
      };

      if (data.get("price-exclusive")) {
        placement.price?.push({
          value: Number(data.get("price-exclusive")),
          type: PriceType.EXCLUSIVE,
        });
      }

      const resp = await placementService.save(placement);

      // TODO error handling

      return resp.data;
    } else {
      throw new Error("No website selected");
    }
  };

  // TODO error handling
  const { mutate, isPending } = useMutation({
    mutationFn: savePlacement,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["placements"] });
      onSubmit && onSubmit();
    },
  });

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (website && formRef.current) {
      const formData = new FormData(formRef.current);
      mutate(formData);
    }
  }

  return (
    <>
      <form method="post" className="" ref={formRef}>
        <input type="hidden" name="id" value={""} />
        <input type="hidden" name="tag" value={""} />
        <div className="flex flex-1 flex-col justify-between">
          <div className="space-y-6 pb-5 pt-2">
            <div>
              <label
                htmlFor="placement-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="placement-name"
                  name="placement-name"
                  type="text"
                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <Accordion>
              <AccordionTab title="Description">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="description"
                      name="description"
                      rows={4}
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="tags"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Tags
                  </label>
                  <div className="mt-2">
                    <ChipsInput name="tags" />
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab title="Category">
                <fieldset>
                  <legend className="text-sm font-medium leading-6 text-gray-900">
                    Category
                  </legend>
                  <div className="mt-2 space-y-4 pb-5">
                    <div className="relative flex items-start">
                      <div className="absolute flex h-6 items-center">
                        <input
                          defaultChecked
                          id="category-standalone"
                          name="category"
                          type="radio"
                          value={PlacementCategory.STANDALONE}
                          aria-describedby="category-standalone-description"
                          className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                        />
                      </div>
                      <div className="pl-7 text-sm leading-6">
                        <label
                          htmlFor="category-standalone"
                          className="font-medium text-gray-900"
                        >
                          Standalone
                        </label>
                        <p
                          id="category-standalone-description"
                          className="text-gray-500"
                        >
                          Fixed placement that does not change depending on
                          content.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="relative flex items-start">
                        <div className="absolute flex h-6 items-center">
                          <input
                            id="category-in-content"
                            name="category"
                            type="radio"
                            value={PlacementCategory.IN_CONTENT}
                            aria-describedby="category-in-content-description"
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          />
                        </div>
                        <div className="pl-7 text-sm leading-6">
                          <label
                            htmlFor="category-in-content"
                            className="font-medium text-gray-900"
                          >
                            In content
                          </label>
                          <p
                            id="category-in-content-description"
                            className="text-gray-500"
                          >
                            Placement surrounded by content, such as an article
                            or blog post.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="relative flex items-start">
                        <div className="absolute flex h-6 items-center">
                          <input
                            id="category-in-list"
                            name="category"
                            type="radio"
                            value={PlacementCategory.IN_LIST}
                            aria-describedby="category-in-list-description"
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          />
                        </div>
                        <div className="pl-7 text-sm leading-6">
                          <label
                            htmlFor="category-in-list"
                            className="font-medium text-gray-900"
                          >
                            In list
                          </label>
                          <p
                            id="category-in-list-description"
                            className="text-gray-500"
                          >
                            Placement located in a list or a grid, such as a
                            gallery, list of articles and similar.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </AccordionTab>
            </Accordion>
          </div>
        </div>

        <div className="overflow-hidden rounded-lg bg-gray-50">
          <div className="px-4 py-5 sm:p-6">
            <div className="text-base font-semibold leading-6 text-gray-900">
              Price setup
            </div>
            <p className="text-sm text-gray-500 pb-6">
              Set the price for both standard and exclusive ads. Suggestion is
              to set exclusive price to standard * 5.
            </p>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="price-standard"
                  className="relative flex items-center gap-1 text-sm font-medium leading-6 text-gray-900"
                >
                  <p>Standard</p>
                  <Tooltip
                    className="-left-2"
                    text="When an ad is standard it will rotate with all other active standard ads that are targeting this placement."
                  />
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">RSD</span>
                  </div>
                  <input
                    id="price-standard"
                    name="price-standard"
                    type="text"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    className="block w-full rounded-md border-0 py-1.5 pl-12 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="price-exclusive"
                  className="flex items-center gap-1 text-sm font-medium leading-6 text-gray-900"
                >
                  <p>Exclusive</p>
                  <Tooltip
                    className="-right-2"
                    text="When an ad is exclusive no other ad will be shown, as long as it is active. Leave empty if you don't want to allow exclusive ads."
                  />
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">RSD</span>
                  </div>
                  <input
                    id="price-exclusive"
                    name="price-exclusive"
                    type="text"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    className="block w-full rounded-md border-0 py-1.5 pl-12 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex flex-shrink-0 justify-end py-4">
        <button
          disabled={isPending}
          type="button"
          onClick={onCancel}
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 disabled:ring-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed transition-all duration-300"
        >
          Cancel
        </button>
        <button
          disabled={isPending}
          onClick={handleSubmit}
          type="submit"
          className="min-w-24 ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-100 disabled:cursor-not-allowed transition-all duration-300"
        >
          {isPending ? (
            <Cog8ToothIcon className="animate-spin-slow h-5 w-5" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
};

export default PlacementForm;
