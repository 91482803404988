import React, { ReactNode } from "react";
import TreeItem from "./TreeItem";
import { QueryKey } from "@tanstack/react-query";

export interface TreeNode {
  label: string | ReactNode;
  value: { id?: number };
  children?: TreeNode[];
  childrenQueryOptions?: {
    queryKey: QueryKey;
    queryFn: () => Promise<TreeNode[]>;
  };
}

export interface TreeItemProps {
  node: TreeNode;
}

const TreeView: React.FC<{ items: TreeNode[] }> = ({ items }) => {
  return (
    <div className="w-fit">
      {items.map((item, index) => (
        <TreeItem
          key={item.value.id}
          node={item}
        />
      ))}
    </div>
  );
};

export default TreeView;
