import { ChevronRightIcon } from "lucide-react";
import { AdTarget } from "../models/api/ad-target.api";
import { cn } from "../lib/shadcn/utils";

const AdTargetingLabel: React.FC<{ target: AdTarget, className?: string }> = ({ target, className }) => {
    const placement = target.placement;
    const price = placement?.price?.find(p => p.type === target.priceType);
  return (
    <>
      {placement && (
        <ol className={cn(className, "flex items-center")}>
          <li>
            <div className="flex items-center">
              {placement.website.name}
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              {placement.name}
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              {price?.value} RSD{" "}
              <span className="pl-1 text-gray-400 lowercase">{"/" + price?.type}</span>
            </div>
          </li>
        </ol>
      )}
    </>
  );
};

export default AdTargetingLabel;
