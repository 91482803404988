import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { ElementType, InputHTMLAttributes, MouseEvent, useEffect, useState } from "react";
import { cn } from "../lib/shadcn/utils";
import Tooltip from "./Tooltip";

export type StateProps = {
  value: string;
  icon: ElementType;
  bgColor?: string;
  selectedIconColor?: string;
  unselectedIconColor?: string;
  label?: string;
  tooltip?: string;
};

type MultiStateToggleProps = {
  value?: number;
  states: StateProps[]; 
  onChange?: (value: number) => void;
  classNames?: string;
};

const offState = {
  value: "off",
  icon: XMarkIcon,
  unselectedIconColor: "text-gray-400",
  selectedIconColor: "text-gray-400",
} as StateProps;

const MultiStateToggle: React.FC<MultiStateToggleProps> = ({
  value = 0,
  states,
  onChange,
  classNames
}) => {
  const [currentIndex, setCurrentIndex] = useState(value);

  useEffect(() => {
    setCurrentIndex(value);
  }, [value]);

  const completeStates = [offState, ...states];

  const handleToggle = (event: any, index?: number) => {
    event.preventDefault?.();
    event.stopPropagation?.();
    setCurrentIndex((currentIndex) => {
      const newIndex = index ?? ((currentIndex + 1) % completeStates.length);
      console.log("handleToggle: " + newIndex);
      onChange?.(newIndex);
      return newIndex;
    });
  };

  const bgColor =
    currentIndex > 0
      ? completeStates[currentIndex].bgColor ?? "bg-blue-600"
      : "bg-gray-200";
  const unselectedIconColor =
    completeStates[currentIndex].unselectedIconColor ?? "text-blue-400";
  const selectedIconColor =
    completeStates[currentIndex].selectedIconColor ?? "text-blue-600";

  return (
    <div className={cn(classNames, "flex items-center")}>
      <button
        onClick={(event) => handleToggle(event)}
        className={`group relative inline-flex h-6 ${"w-" + completeStates.length * 6} flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2`}
      >
        <span
          className={cn(
            "w-" + (currentIndex + 1) * 6,
            bgColor,
            "absolute -top-0.5 -left-0.5 z-10 h-6 cursor-pointer rounded-full p-0.5 transition-all duration-200 ease-in-out"
          )}
        ></span>
        {completeStates.map((state, index) => (
          <state.icon
            onClick={(event: any) => index != currentIndex ? handleToggle(event, index) : handleToggle(event, 0)}
            key={"i" + index}
            className={cn(
              index != 0 ? "ml-1" : "",
              index != currentIndex
                ? index > currentIndex
                  ? "text-gray-400"
                  : unselectedIconColor
                : selectedIconColor,
              "inline-block p-0.5 h-5 z-30"
            )}
          />
        ))}
        <span
          aria-hidden="true"
          className={cn(
            "translate-x-" + currentIndex * 6,
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out absolute z-20"
          )}
        ></span>
      </button>
      {currentIndex > 0 && completeStates[currentIndex].label && (
        <span
          className={cn(
            "translate-x-" + completeStates.length * 6,
            "ml-2 absolute text-sm flex flex-row items-center"
          )}
        >
          <span className="font-medium text-gray-900 mr-1">
            {completeStates[currentIndex].label}
          </span>
          {completeStates[currentIndex].tooltip && (
            <Tooltip text={completeStates[currentIndex].tooltip} />
          )}
        </span>
      )}
    </div>
  );
};

export default MultiStateToggle;
