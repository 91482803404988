import { BellIcon } from "@heroicons/react/24/outline";
import WebsiteSelector from "../WebsiteSelector";

const Topbar: React.FC = () => {
  return (
    <div className="sticky top-0 z-40 bg-white flex-row border-b py-2 px-4 md:flex hidden">
      <div className="group relative flex items-center">
        <div className="pr-1 relative z-10 max-w-6 -translate-y-2 overflow-hidden text-3xl font-bold transition-all duration-500 ease-in-out group-hover:max-w-96">
          Ozone
        </div>
        <div className="relative z-20 -translate-x-2 -translate-y-5 transition-all duration-300 ease-in-out group-hover:px-2">
          <div className="absolute z-20 rotate-12 scale-[2] translate-x-0.5 -translate-y-1 transform text-3xl font-bold text-white">
            /
          </div>
          <div className="absolute z-20 rotate-12 scale-x-100 transform text-3xl font-bold">
            /
          </div>
        </div>

        <div className="relative z-10 mt-4 max-w-5 -translate-x-1.5 -translate-y-0.5 overflow-hidden text-3xl font-bold transition-all delay-300 duration-500 ease-in-out group-hover:max-w-96">
          Ads
        </div>
      </div>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 flex-row-reverse">
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <WebsiteSelector />

          {/* Separator */}
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-10 lg:w-px lg:bg-gray-200 h-full"
          />

          <button
            type="button"
            className="-m-2.5 -ml-5 p-2.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
