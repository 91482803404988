import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface PaginatorItem {
  active: boolean;
  interactive: boolean;
  text: string;
}

const dots = {
  active: false,
  interactive: false,
  text: "...",
};

/**
 * Renders a paginator component with the given number of pages and the current page.
 *
 * @param {number} pagesCount - The total number of pages.
 * @param {number} currentPage - The current page number, starts from 0.
 * @return {JSX.Element} The rendered paginator component.
 */
const Paginator: React.FC<{
  pagesCount: number;
  currentPage: number;
  className?: string;
  onPageChange?: (page: number) => void;
}> = ({ pagesCount, currentPage, className, onPageChange }) => {

  const { pathname, search } = useLocation();
  const base = pathname.endsWith(`/${currentPage}`)
    ? pathname.substring(0, pathname.length - String(currentPage).length)
    : `${pathname}/page/`;
  const url = (page: number) => onPageChange ? "#" : `${base}${page}${search}`;

  const onNavLinkClick = (page: number, event: React.MouseEvent) => {
    if (onPageChange) {
      onPageChange(page);
      event.preventDefault();
    }
  };

  const items: PaginatorItem[] = useMemo(() => {
    // if at most 1 page show none
    if (pagesCount <= 1) {
      return [];
    }

    // if less than 6 pages, show all
    if (pagesCount <= 5) {
      return Array(pagesCount)
        .fill(0)
        .map((_, i) => ({
          active: i + 1 == currentPage,
          interactive: true,
          text: String(i + 1),
        }));
    }

    // if active is first or second then show 1, 2, 3 ... X
    if (currentPage == 1 || currentPage == 2) {
      return Array(3)
        .fill(0)
        .map((_, i) => ({
          active: i + 1 == currentPage,
          interactive: true,
          text: String(i + 1),
        }))
        .concat([
          dots,
          { active: false, interactive: false, text: String(pagesCount) },
        ]);
    }

    // if active is last or first to last then show 1 ... X - 2, X - 1, X
    if (currentPage == pagesCount || currentPage == pagesCount - 1) {
      return [
        { active: false, interactive: false, text: String(1) },
        dots,
      ].concat(
        Array(3)
          .fill(0)
          .map((_, i) => ({
            active: pagesCount - 2 + i == currentPage,
            interactive: true,
            text: String(pagesCount - 2 + i),
          }))
      );
    }

    // if active is in the middle then show 1 ... A - 1, A, A + 1 ... X
    return [
      { active: false, interactive: false, text: String(1) },
      dots,
      { active: false, interactive: true, text: String(currentPage - 1) },
      { active: true, interactive: true, text: String(currentPage) },
      { active: false, interactive: true, text: String(currentPage + 1) },
      dots,
      { active: false, interactive: false, text: String(pagesCount) },
    ];
  }, [pagesCount, currentPage]);

  const linksClasses =
    "inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium";
  const enabledLinksClasses =
    "text-gray-500 hover:border-gray-300 hover:text-gray-700";
  const disabledLinksClasses = "text-gray-200";

  return (
    <nav className={`flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 ${className}`}>
      <div className="-mt-px flex w-0 flex-1">
        {currentPage > 1 && (
          <NavLink
            onClick={(event) => onNavLinkClick(currentPage - 1, event)}
            to={url(currentPage - 1)}
            className={`${linksClasses}${enabledLinksClasses}`}
          >
            <ArrowLongLeftIcon
              aria-hidden="true"
              className="mr-3 h-5 w-5 text-gray-400"
            />
            Previous
          </NavLink>
        )}
        {currentPage <= 1 && (
          <span className={`${linksClasses} ${disabledLinksClasses}`}>
            <ArrowLongLeftIcon
              aria-hidden="true"
              className={`mr-3 h-5 w-5 ${disabledLinksClasses}`}
            />
            Previous
          </span>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {items.map((item, index) => {
          if (item.active) {
            return (
              <NavLink
                onClick={(event) => onNavLinkClick(Number(item.text), event)}
                to={url(Number(item.text))}
                key={index}
                aria-current="page"
                className="inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"
              >
                {item.text}
              </NavLink>
            );
          } else if (item.interactive) {
            return (
              <NavLink
                onClick={(event) => onNavLinkClick(Number(item.text), event)}
                to={url(Number(item.text))}
                key={index}
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                {item.text}
              </NavLink>
            );
          } else {
            return (
              <span
                key={index}
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
              >
                {item.text}
              </span>
            );
          }
        })}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {currentPage != pagesCount && (
          <NavLink
            onClick={(event) => onNavLinkClick(currentPage + 1, event)}
            to={url(currentPage + 1)}
            className={`${linksClasses}${enabledLinksClasses}`}
          >
            Next
            <ArrowLongRightIcon
              aria-hidden="true"
              className="ml-3 h-5 w-5 text-gray-400"
            />
          </NavLink>
        )}
        {currentPage == pagesCount && (
          <span className={`${linksClasses} ${disabledLinksClasses}`}>
            Next
            <ArrowLongRightIcon
              aria-hidden="true"
              className={`ml-3 h-5 w-5 ${disabledLinksClasses}`}
            />
          </span>
        )}
      </div>
    </nav>
  );
};

export default Paginator;
