import React, { useState } from "react";
import Paged from "../../../components/pagination/Paged";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_PAGE_SIZE } from "../../../api/common";
import adService from "../../../api/ad.service";
import { useParams } from "react-router-dom";
import { Ad } from "../../../models/api/ad.api";
import APIPaged from "../../../models/api/paged.api";

const PagedPlacementDetailAdList: React.FC<{
  ads?: APIPaged<Ad>;
}> = ({ ads }) => {
  return (
    <>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-900/10">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="relative px-4 py-4 sm:px-6 md:px-8 lg:px-8 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {ads?.content?.map((ad) => (
                  <tr key={ad.id}>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div>
                          <div className="font-medium text-gray-900">
                            {ad.name}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {ad.campaign?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {new Date(ad.startDate).toISOString()}
                      </div>
                      <div className="mt-1 text-gray-500">
                        {new Date(ad.endDate).toISOString()}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Active
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                      {ad.name}
                    </td>
                    <td className="relative whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm font-medium sm:pr-0">
                      <a
                        href="#"
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit<span className="sr-only">, {ad.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const PlacementDetailAdList = () => {
  const { placementId } = useParams();
  const [adPageCount, setAdPageCount] = useState(-1);

  const [adsPageNumber, setAdsPageNumber] = useState(1);

  const adsQuery = useQuery({
    queryKey: [
      "ads",
      "placementId",
      placementId,
      adsPageNumber - 1,
      DEFAULT_PAGE_SIZE,
    ],
    queryFn: () =>
      adService.list(adsPageNumber - 1, DEFAULT_PAGE_SIZE, Number(placementId)),
  });

  const ads = adsQuery?.data?.data;

  if (!adsQuery?.isFetching && adPageCount == -1) {
    setAdPageCount(ads?.totalPages || 0);
  }

  return (
    <>
      <Paged
        currentPage={adsPageNumber}
        pagesCount={adPageCount}
        onPageChange={(page: number) => setAdsPageNumber(page)}
        className="sm:px-6 md:px-8 lg:px-8"
        loading={adsQuery?.isFetching}
        itemName="ad"
        createUrl="/ad/new"
        totalItems={ads?.totalElements || 0}
      >
        <PagedPlacementDetailAdList ads={ads} />
      </Paged>
    </>
  );
};

export default PlacementDetailAdList;
