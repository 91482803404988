import UIPropsBase from "../../models/ui/props";

export interface AccordionTabProps extends UIPropsBase {
  title: string;
  open?: boolean;
  onClick?: () => void;
}

const AccordionTab: React.FC<AccordionTabProps> = ({
  title,
  children,
  open,
  onClick,
}) => {
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className="w-full flex items-center py-2 gap-2 font-semibold text-start text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 "
      >
        <svg
          className={`${open ? "hidden" : ""} block size-3.5`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14"></path>
          <path d="M12 5v14"></path>
        </svg>
        <svg
          className={`${open ? "" : "hidden"} block size-3.5`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14"></path>
        </svg>
        {title}
      </button>
      <div className={`${open ? "max-h-svh" : "max-h-0"} text-gray-600 transition-[max-height] duration-300 overflow-hidden ease-in-out`}>{children}</div>
    </>
  );
};

export default AccordionTab;
