import { useState, ReactElement, cloneElement } from "react";
import AccordionTab, { AccordionTabProps } from "./AccordionTab";

const Accordion: React.FC<{
  children: ReactElement<AccordionTabProps, typeof AccordionTab>[];
}> = ({ children }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(() => {
    let calculatedOpenIndex = null;

    children.forEach((child, index) => {
      if (child.props.open) {
        calculatedOpenIndex = index;
      }
    });

    return calculatedOpenIndex;
  });

  const handleAccordionClick = (index: number) => {
    setOpenIndex((openIndex) => (openIndex === index ? null : index));
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      {children.map((accordion, index) =>
        cloneElement(accordion, {
          key: accordion.key || accordion.props.title,
          open: openIndex === index,
          onClick: () => handleAccordionClick(index),
        })
      )}
    </div>
  );
};

export default Accordion;
