import { LoaderFunctionArgs, redirect } from "react-router-dom";

export function loader() {
  const token = getAuthToken();
  return token ? token : redirect(process.env.REACT_APP_API_URL + "/auth");
}

export const authSuccessLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const token = url.searchParams.get("token");

  if (token) {
    localStorage.setItem("token", token);
  }

  return redirect("/");
};

export function getAuthToken(): string | null {
  let token = localStorage.getItem("token");
  return isExpired(token) ? null : token;
}

function isExpired(token: string | null): boolean {
  if (token) {
    const tokenParts = token.split(".");
    if (tokenParts.length === 3) {
      try {
        const payload =  JSON.parse(atob(tokenParts[1]).toString());
        if (payload.exp) {
          const expirationDate = new Date(0);
          expirationDate.setUTCSeconds(payload.exp);
          if (expirationDate <= new Date()) {
            return true; // Token has expired
          } else {
            return false; // Token is still valid
          }
        }
      } catch (error) {
        console.error("Failed to parse JWT payload:", error);
      }
    }
  }

  return true;
}
